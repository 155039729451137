export const radios = [
  {
    name: 'Antenne Bayern',
    id: 'antenne-bayern',
    stream:
      'https://s2-webradio.antenne.de/antenne/stream?aw_0_1st.playerId=AntenneBayernWebPlayer&aw_0_1st.skey=1662327040492&aw_0_req.userConsentV2=CPexykAPexykAAFADBDECfCgAAAAAAAAAAYgAAAAAAEhIAMAAQQaDQAYAAgg0IgAwABBBoVABgACCDQyADAAEEGh0AGAAIINEIAMAAQQaJQAYAAgg0UgAwABBBoA.YAAAAAAAAAAA&companionAds=false&companion_zone_alias=41%2C42%2C40%2C731%2C752%2C756',
  },
  {
    name: 'Bremen Vier',
    id: 'bremen-vier',
    stream:
      'https://f111.rndfnk.com/ard/rb/bremenvier/live/mp3/128/stream.mp3?cid=01FC1W6QK48EBYZ3RJW932FC1E&sid=2EJwdqSAZAMMj4dCbzQn3cOxgoG&token=Bkmmb01ZrGMbGm7daMlME5lHsSVYFbrm7soWWftpEbI&tvf=RvtHLVLZERdmMTExLnJuZGZuay5jb20',
  },
  {
    name: 'WDR Live',
    id: 'wdr-live',
    stream:
      'https://f111.rndfnk.com/ard/wdr/1live/live/mp3/128/stream.mp3?aggregator=radio-de&cid=01FBRZTS1K1TCD4KA2YZ1ND8X3&sid=2EJx1IiAUBaOT5twd7SYd92Fjeh&token=ro5j0IK3u66gKS7vdjOPBGQG1UC_WtcJsHn7gCxM-ys&tvf=6yCorX3ZERdmMTExLnJuZGZuay5jb20',
  },
  {
    name: 'Big FM',
    id: 'big-fm',
    stream:
      'https://audiotainment-sw.streamabc.net/atsw-bigfm-aac-128-6355201?sABC=63151n15%230%2311008q4qo7p8qp1432o8999p732p78n6%23enqvbqr&aw_0_1st.playerid=radiode&amsparams=playerid:radiode;skey:1662327317',
  },
  {
    name: 'Bayern 3',
    id: 'bayern-3',
    stream:
      'https://f111.rndfnk.com/ard/br/br3/live/aac/48/stream.aac?aggregator=radio-de&cid=01FBPVFWX7C216W6WY4M4M7MF7&sid=2EJxB20lxOEFCFKMkyc8kzwOcqT&token=_z3FiiGd8li2MaUgbx704X-VgTJIbZbbwg0VEIydXv4&tvf=_8pdio_ZERdmMTExLnJuZGZuay5jb20',
  },
];
