import { useEffect, useReducer, useState } from 'react';
import './App.css';
import { radios } from './data/radios';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as ForwardIcon } from './icons/forward.svg';
import { ReactComponent as BackwardIcon } from './icons/backward.svg';
import { ReactComponent as SpinnerIcon } from './icons/spinner.svg';

enum CountActionKind {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

interface CountAction {
  type: CountActionKind;
  payload: number;
}

interface CountState {
  count: number;
}

const reducer = (state: CountState, action: CountAction) => {
  switch (action.type) {
    case CountActionKind.INCREASE:
      return {
        count:
          state.count !== radios.length - 1 ? state.count + action.payload : 0,
      };

    case CountActionKind.DECREASE:
      return {
        count: state.count !== 0 ? state.count - action.payload : 5,
      };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, { count: 0 });
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>();
  const [shouldPlay, setShouldPlay] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (audio && shouldPlay) setLoading(true);
    if (audio) audio.pause();
    const newAudio = new Audio(radios.at(state.count)?.stream);
    setAudio(newAudio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.count]);

  useEffect(() => {
    if (audio) {
      shouldPlay && setLoading(true);
      shouldPlay ? audio?.play() : audio.pause();
      audio.onplaying = () => setLoading(false);
    }
  }, [audio, shouldPlay]);

  return (
    <div className='app'>
      <h2>{radios.at(state.count)?.name}</h2>
      <div className='poster-card'>
        <img
          src={`/assets/${radios.at(state.count)?.id}.png`}
          alt={radios.at(state.count)?.id}
        />
      </div>
      <div className='playback-actions'>
        <button
          className='playback-button'
          onClick={() =>
            dispatch({ type: CountActionKind.DECREASE, payload: 1 })
          }
        >
          <BackwardIcon />
        </button>
        <button
          className='playback-button'
          onClick={() => setShouldPlay(!shouldPlay)}
          disabled={loading}
        >
          {loading ? (
            <SpinnerIcon className='spinner' />
          ) : shouldPlay ? (
            <PauseIcon />
          ) : (
            <PlayIcon />
          )}
        </button>
        <button
          className='playback-button'
          onClick={() =>
            dispatch({ type: CountActionKind.INCREASE, payload: 1 })
          }
        >
          <ForwardIcon />
        </button>
      </div>
    </div>
  );
};

export default App;
